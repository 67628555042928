import React, { useState } from "react"
import Layout from "../components/layout"
import ImgMessageSent from "../assets/images/message-sent.svg"
import Joi from "@hapi/joi"
import axios from "axios"
import SEO from "../components/seo"

export default () => {
  const [formData, setFormData] = useState([
    {
      name: "name",
      value: "",
      required: true,
    },
    {
      name: "email",
      value: "",
      required: true,
    },
    {
      name: "budget",
      value: "1",
      required: false,
    },
    {
      name: "message",
      value: "",
      required: true,
    },
  ])

  const validate = formData => {
    return Joi.object({
      name: Joi.string()
        .min(2)
        .max(100)
        .required()
        .label("Name"),
      email: Joi.string()
        .min(2)
        .max(100)
        .email({ tlds: { allow: false } })
        .required()
        .label("Email"),
      budget: Joi.string()
        .min(1)
        .max(1)
        .label("Budget"),
      message: Joi.string()
        .min(5)
        .max(20000)
        .required()
        .label("Project details"),
    }).validate(formData)
  }

  const [error, setError] = useState("")
  const [sending, setSending] = useState(false)
  const [sent, setSent] = useState(false)

  const sendEmail = async data => {
    const apiEndpoint =
      "https://api.emailjs.com/api/v1.0/email/send"
    const result = await axios.post(apiEndpoint, { user_id: "1xo77ZkGFEse4ti3Q", template_id: 'template_lyje9fd', service_id: 'service_91imf1c', template_params: data })
    return result
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const formDataValidate = formData.reduce((prev, current) => {
      return { ...prev, [current.name]: current.value }
    }, {})
    const { error } = validate(formDataValidate)
    if (error) {
      const validationMessage = error.details[0].message
      setError(validationMessage)
    } else {
      setError("")
      setSending(true)
      try {
        const result = await sendEmail(formDataValidate)
        console.log(result)
        setSent(true)
      } catch (e) {
        setError(
          "Email couldn't be sent. Please try again later or contact us via phone."
        )
        setSent(false)
      }
      setSending(false)
    }
  }

  const handleFieldChange = ({ target: { name, value } }) => {
    const newFormData = [...formData]
    const fieldIndex = newFormData.findIndex(field => {
      return field.name === name
    })
    newFormData[fieldIndex].value = value
    setFormData(newFormData)
  }

  const fieldValue = name => {
    const field = formData.find(field => {
      return field.name === name
    })
    return field.value
  }

  return (
    <Layout>
      <SEO title="Contact Us" />
      <div className="container page contact-page">
        <h1>Contact us</h1>
        <h5>
          We would love to hear about your ideas or details for your project.
          Fill out the form below and we will get back to you.
        </h5>
        <div className="row justify-content-center contact-form-container">
          <div className="col-md-7">
            {!sent ? (
              <form className="contact-form" onSubmit={handleSubmit} noValidate>
                {error && <div className="alert alert-danger">{error}</div>}
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    id="name"
                    placeholder="Full name"
                    onChange={handleFieldChange}
                    value={fieldValue("name")}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="email@example.com"
                    onChange={handleFieldChange}
                    value={fieldValue("email")}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="budget">
                    Budget which you plan to allocate
                  </label>
                  <select
                    name="budget"
                    className="form-control"
                    id="budget"
                    onChange={handleFieldChange}
                    value={fieldValue("budget")}
                  >
                    <option value="1">up to €1.000</option>
                    <option value="2">€1.000 - €5.000</option>
                    <option value="3">€5.000 - €10.000</option>
                    <option value="4">€10.000 +</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="message">Project details</label>
                  <textarea
                    className="form-control"
                    name="message"
                    id="message"
                    rows="5"
                    placeholder="Tell us about your project"
                    onChange={handleFieldChange}
                    value={fieldValue("message")}
                  ></textarea>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={sending}
                  >
                    {sending ? "Sending..." : "Send"}
                  </button>
                </div>
              </form>
            ) : (
              <div className="jumbotron contact-form-sent">
                <h3 className="text-center">Thank you!</h3>
                <p>
                  Your message has been sent and we will get back to you as soon
                  as possible.
                </p>
                <img
                  src={ImgMessageSent}
                  alt="Message sent"
                  className="img-fluid"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}
